import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {ResetPassword} from './components/ResetPassword'
import EmailConfirmation from './components/EmailConfirmation'
import {SetupNewPassword} from './components/SetupNewPassword'
import {Error404} from '../errors/components/Error404'

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='*' element={<Navigate to='/error/404' />} />
        <Route path='login' element={<Login />} />
        {/* <Route path='registration' element={<Registration />} /> */}
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password/:token' element={<ResetPassword />} />
        <Route path='email-confirmation' element={<EmailConfirmation />} />
        <Route path='email-confirmation/:token' element={<EmailConfirmation />} />
        <Route path='password-setup' element={<SetupNewPassword />} />
        <Route path='password-setup/:token' element={<SetupNewPassword />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
