import CustomInput, {
  createZodValidationRule,
} from '../../../admin/modules/teacher/createteacher/CustomInput'
import {z} from 'zod'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import useCreatePassword from '../../../admin/hooks/useCreatePassword'

const schemaPassword = z
  .string()
  .min(8, {message: 'Please enter a valid password of at least 8 charachters'})
  .max(30, {message: 'Please enter a Valid password under 30 characters'})
const schemaConfirmPassword = z
  .string()
  .min(8, {message: 'Please enter a valid password of at least 8 charachters'})
  .max(30, {message: 'Please enter a Valid password under 30 characters'})

export function SetupNewPassword() {
  //This hook is for extracting token
  const {token} = useParams()
  const location = useLocation() //No need it yet

  //This Hook if for Mutating User Data
  const {mutate, isError, isSuccess} = useCreatePassword()

  //This Hook if for handing form
  const {
    control, // RHF control
    handleSubmit,
    formState: {errors},
  } = useForm()

  const onSubmit = (data: any) => {
    if (data.password == data.confirmPassword && token) {
      const createPasswordData = {
        auth_token: token,
        password: data.password,
      }
      mutate(createPasswordData)
    } else {
      alert('Passwords do no match')
    }
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Create Password</h1>
          {/* end::Title */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter password to gain access to your account.
          </div>
        </div>
        <div className='fv-row mb-8'>
          <CustomInput<number>
            labelText='Password'
            type='password'
            control={control}
            name='password' // Field name
            rules={createZodValidationRule(schemaPassword, 'password')}
            errorMessage={errors.password ? errors.password?.message?.toString() : ''}
          />
          <CustomInput
            labelText='Confirm Password'
            type='password'
            control={control}
            name='confirmPassword'
            rules={createZodValidationRule(schemaConfirmPassword, 'confirmPassword')}
            errorMessage={errors.confirmPassword ? errors.confirmPassword?.message?.toString() : ''}
          />
        </div>
        <div className='d-flex justify-content-center'>
          <button type='button' className='btn btn-light-primary ' onClick={handleSubmit(onSubmit)}>
            Submit
          </button>
        </div>
      </form>
    </>
  )
}
