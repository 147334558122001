import {useParams} from 'react-router-dom'
import MetaTags from '../../../Meta/MetaTags'
import {useFetchAllTasksOfClassByStudentId} from '../../../admin/hooks/useTaskAndStudents'
import LastTask from '../../modules/components/assets/LastTask'
import MidTask from '../../modules/components/assets/MidTask'
import RightTask from '../../modules/components/assets/RightTask'
import {PageTitleUpdater} from '../../../../_metronic/layout/components/header/PageTitleUpdater'
import {StudentClassTasksBreadCrumbs} from '../../../breadcrumbs/breadcrums'
import {useFetchClassById} from '../../../admin/hooks/useClass'
import {NO_TASKS_FOR_CLASS, TASKS_NOT_LOADED} from '../../../constants/AdminConstants'
import React, {useEffect, useRef, useState} from 'react'
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'
import {RESET, ZOOM_IN, ZOOM_OUT} from '../../../constants/AppConstants'
import {screenSizeConfig} from '../../../helpers/WeekStructureHelpers'
import {Spinner} from 'react-bootstrap'

const HomePage = () => {
  const m5ContainerRef = useRef<HTMLDivElement | null>(null) // Step 2: Create a ref
  const [widthParent, setWidthParent] = useState(800)
  const {id} = useParams()
  const classID = parseInt(id!)
  const {data: dataTasks} = useFetchAllTasksOfClassByStudentId(classID)
  const {data: dataClass} = useFetchClassById(classID)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 562)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  // const [config, setConfig] = useState(screenSizeConfig.sm) // Initialize with default config

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576) //562
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    //!This is for preventing user to use pinch, or keyboard keys to zoom on the current page
    // Function to prevent pinch-to-zoom on desktop browsers
    const preventPinchZoom = (e: WheelEvent) => {
      if (e.ctrlKey && e.deltaY !== 0) {
        e.preventDefault()
      }
    }
    // Function to prevent zoom shortcuts on non-touch devices
    const preventZoomShortcuts = (e: KeyboardEvent) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key === '+' || e.key === '-' || e.keyCode === 187 || e.keyCode === 189)
      ) {
        e.preventDefault()
      }
    }

    // Check if the device does not support touch events (likely a desktop)
    if (!('ontouchstart' in window)) {
      window.addEventListener('wheel', preventPinchZoom, {passive: false})
      window.addEventListener('keydown', preventZoomShortcuts, {passive: false})
    }

    return () => {
      if (!('ontouchstart' in window)) {
        window.removeEventListener('wheel', preventPinchZoom)
        window.removeEventListener('keydown', preventZoomShortcuts)
      }
    }
  }, [])

  useEffect(() => {
    //! This useEffect calculates the area of the upper div after removing sidebar

    console.log('I ran')

    const element = m5ContainerRef.current

    if (element) {
      const width = element.clientWidth
      setWidthParent(width)
      console.log('Width: ' + width)
    }
  }, [dataTasks]) // Run this effect once when the component mounts
  document.documentElement.style.setProperty('--calculated-width', `${widthParent}px`)

  // useEffect(() => {
  //   const handleResize = (): void => {
  //     const newWidth = window.innerWidth
  //     setScreenWidth(newWidth)

  //     const newConfig =
  //       newWidth <= 768
  //         ? screenSizeConfig.sm
  //         : newWidth <= 992
  //         ? screenSizeConfig.md
  //         : screenSizeConfig.lg

  //     // Only update the config if it has changed
  //     if (newConfig !== config) {
  //       setConfig(newConfig)
  //     }
  //   }

  //   window.addEventListener('resize', handleResize)
  //   console.log(JSON.stringify(config))

  //   return (): void => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [window.innerWidth]) //TODO Commented it for future purpose

  if (!dataTasks) return <div> {TASKS_NOT_LOADED}</div>

  const newWidth = window.innerWidth
  const config =
    newWidth <= 768
      ? screenSizeConfig.sm
      : newWidth <= 1440
      ? screenSizeConfig.md
      : screenSizeConfig.lg

  let topValueLeft = config.topValue.left
  let topValueRight = config.topValue.right

  const calculateTop = (index: number, orientation: string) => {
    let data = 0.0
    if (orientation === 'left') {
      if (index >= 4) {
        data = index * config.calculateTop.orientationLeft.conditionGreaterThan4
      } else {
        if (index === 1) {
          data = index * config.calculateTop.orientationLeft.conditionLessThan4.conditionEqual1
        } else {
          data = index * config.calculateTop.orientationLeft.conditionLessThan4.conditionNotEqual1
        }
      }
    } else {
      if (index >= 4) {
        data = index * config.calculateTop.orientationRight.conditionGreaterThan4
      } else {
        data = index * config.calculateTop.orientationRight.conditionLessThan4
      }
    }

    const calcData = data + 'px'

    return calcData
  }

  const calculateTopLeftMid = (index: number) => {
    let data
    if (index === 1) {
      data = index * config.calculateTopLeftMid.conditionEqualTo1
    } else {
      data = index * topValueLeft
    }
    topValueLeft = topValueLeft - config.calculateTopLeftMid.decrement
    const calcData = data + 'px'
    return calcData
  }

  const calculateTopRightMid = (index: number) => {
    let data
    if (index === 2) {
      data = index * config.calculateTopRightMid.conditionEqualTo2
    } else {
      data = index * topValueRight
    }
    topValueRight = topValueRight - config.calculateTopRightMid.decrement
    const calcData = data + 'px'
    return calcData
  }

  if (dataTasks.length === 0 || dataTasks === undefined) {
    return (
      <div>
        <h1> {NO_TASKS_FOR_CLASS}</h1>
      </div>
    )
  }

  //This calculates the number of data and display height accordingly
  if (dataTasks.length > 1) {
    const calculatedHeight = 500 + (dataTasks.length - 1) * 300
    // Update the SCSS variable dynamically
    document.documentElement.style.setProperty('--calculated-height', `${calculatedHeight}px`)
  } else {
    const calculatedHeight = 500
    // Update the SCSS variable dynamically
    document.documentElement.style.setProperty('--calculated-height', `${calculatedHeight}px`)
  }

  //   Attach the ref to the parent element
  const handleRef = (node: HTMLDivElement | null) => {
    if (node) {
      m5ContainerRef.current = node
    }
  }

  const renderContent = () => (
    <div className='container position-relative mx-0 '>
      {dataTasks?.map((week, index: number) => (
        <div key={index}>
          {index === 0 && (
            <div>
              <div className={'right-week ' + config.screenSize}>
                <RightTask task={dataTasks[index].tasks} index={dataTasks.length} />
              </div>
            </div>
          )}
          {index === dataTasks.length - 1 && dataTasks.length !== 1 && (
            <div>
              {index % 2 === 0 && index !== 0 ? (
                <div>
                  <div
                    className={'last-week-right ' + config.screenSize}
                    style={{top: calculateTop(index, 'right')}}
                  >
                    <LastTask task={dataTasks[index].tasks} />
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className={'last-week-left ' + config.screenSize}
                    style={{top: calculateTop(index, 'left')}}
                  >
                    <LastTask task={dataTasks[index].tasks} />
                  </div>
                </div>
              )}
            </div>
          )}
          {index !== 0 && index !== dataTasks.length - 1 && (
            <div>
              {index % 2 === 0 ? (
                <div>
                  <div
                    className={'right-mid-week ' + config.screenSize}
                    style={{top: calculateTopRightMid(index)}}
                  >
                    <MidTask task={dataTasks[index].tasks} />
                  </div>
                </div>
              ) : (
                //!Adding a condition for first data
                <div>
                  <div
                    className={'left-mid-week ' + config.screenSize}
                    style={{top: calculateTopLeftMid(index)}}
                  >
                    <MidTask task={dataTasks[index].tasks} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )

  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />

      <PageTitleUpdater breadcrumbs={StudentClassTasksBreadCrumbs}>
        {dataClass?.name + ' Tasks'}
      </PageTitleUpdater>

      <div className='m-5 w-100' ref={handleRef}>
        {isSmallScreen ? (
          // Render without TransformWrapper for small screens
          renderContent()
        ) : (
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={50}
            // centerOnInit
            // initialScale={scale}
            // panning={{disabled: scale === 1, velocityDisabled: true}} // Don't pan when the user didn't zoom into the child
            // alignmentAnimation={{sizeX: 20, sizeY: 20, velocityAlignmentTime: 50}}
            // onZoom={(ref) => setScale(ref.state.scale)}
            wheel={{activationKeys: ['Control', 'Shift']}}
          >
            {({zoomIn, zoomOut, resetTransform}) => (
              <React.Fragment>
                <div className='tools sticky-top zoom-button-fixed '>
                  <button className='btn btn-sm btn-primary  mx-1' onClick={() => zoomIn()}>
                    {ZOOM_IN}
                  </button>
                  <button className='btn btn-sm btn-primary mx-5' onClick={() => zoomOut()}>
                    {ZOOM_OUT}
                  </button>
                  <button className='btn btn-sm btn-primary  mx-1' onClick={() => resetTransform()}>
                    {RESET}
                  </button>
                </div>
                <TransformComponent>{renderContent()}</TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        )}
      </div>
    </>
  )
}

export {HomePage}
