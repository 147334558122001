import * as z from 'zod'
import {
  DESCRIPTION_MESSAGE_MAX,
  DESCRIPTION_MESSAGE_MIN,
  ENTER_EMAIL_MESSAGE,
  SELECT_ATLEAST_ONE,
  SELECT_A_CLASS_MESSAGE,
  SELECT_COUNTER_LIMIT_MESSAGE,
  SELECT_COUNTER_MESSAGE,
  SELECT_NAME_MESSAGE,
  SELECT_OPTION_REQUIRED,
  SELECT_TEACHER_MESSAGE,
  SELECT_WEEK_LIMIT_MESSAGE,
  SELECT_WEEK_MESSAGE,
} from '../constants/ErrorMessagesConstants'

export const schemaName = z.string().min(3, {message: SELECT_NAME_MESSAGE})
export const schemaEmail = z
  .string()
  .min(8, {message: ENTER_EMAIL_MESSAGE})
  .email(ENTER_EMAIL_MESSAGE)

export const schemaDescription = z
  .string()
  .min(3, {message: DESCRIPTION_MESSAGE_MIN})
  .max(2000, {message: DESCRIPTION_MESSAGE_MAX})

export const schemaSelectedOptionsClasses = z.array(z.string()).min(1, {
  message: SELECT_A_CLASS_MESSAGE,
})

export const schemaCounter = z.number().min(1, {message: SELECT_COUNTER_MESSAGE})
// .max(100, {message: SELECT_COUNTER_LIMIT_MESSAGE})

// Define Zod schema for Week
export const schemaWeek = z
  .number()
  .min(1, {message: SELECT_WEEK_MESSAGE})
  .max(7, {message: SELECT_WEEK_LIMIT_MESSAGE})

//?What should me the min and max for Counter and week

//These both are generic, can be used with any select
export const schemaSelectedOptional = z.string().optional()
export const schemaSelectedOptionRequired = z.string().min(1, {
  message: SELECT_OPTION_REQUIRED,
})
//! Issue here
//(1) Add a Class, remove it, then try adding a Student
//(2) On adding teacher it needs a class
export const schemaArrayOfStrings = z.array(
  z.string().min(1, {
    message: SELECT_ATLEAST_ONE,
  })
)

export const schemaArrayOfStringsOptional = z.array(z.string())

export const schemaArrayOfWeek = z.array(
  z.string().min(1, {
    message: SELECT_ATLEAST_ONE,
  })
)
