import {useEffect, useState} from 'react'
import {verifyEmail, resendLink} from '../core/_requests'
import {toast} from 'react-toastify'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import jwt from 'jsonwebtoken'
import {Spinner} from 'react-bootstrap'

interface LocationState {
  email: string
}

function EmailConfirmation() {
  const notifyError = (msg: string) => toast.error(msg)
  const notifySuccess = (msg: string) => toast.success(msg)
  const {token} = useParams()
  const location = useLocation()
  const email = (location.state as LocationState)?.email
  const navigate = useNavigate()

  const [minutes, setMinutes] = useState<number>(0)
  const [hour, setHour] = useState<number>(0)
  const [loading, setLoading] = useState(false)

  const handleVerifyEmail = async (token: string) => {
    setLoading(true)
    try {
      await verifyEmail(token)
    } catch (err) {
      notifyError('Email verification failed')
    }
    setLoading(false)
  }

  const handleResendVerificationLink = async (email: string) => {
    setLoading(true)
    try {
      const res = await resendLink(email)
      if (res?.data) {
        const diffHours = Math.floor(res?.data?.diffInMinutes / 60)
        const diffMinutes = res?.data?.diffInMinutes % 60
        setHour(diffHours)
        setMinutes(diffMinutes)
      }
    } catch (err: any) {
      if (err?.response?.data) {
        notifyError(err?.response?.data?.message)
      } else {
        notifyError('Email verification failed')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (token) {
      handleVerifyEmail(token)
    } else if (email) {
      handleResendVerificationLink(email)
    }
  }, [])

  const handleSubmit = async () => {
    try {
      handleResendVerificationLink(email)
      notifySuccess('Email has been sent successfully')
      navigate('/login')
    } catch (error) {
      console.error((error as Error).message)
    }
  }

  if (loading) return <Spinner />

  return (
    <>
      {token ? (
        <div className='text-center'>
          <h5>
            Your email address has been successfully confirmed. Please proceed to log in to
            continue.
          </h5>
          <button type='submit' className='btn btn-primary' onClick={() => navigate('/auth/login')}>
            Login
          </button>
        </div>
      ) : (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={handleSubmit}
        >
          <div className='text-center mb-8'>
            <h1 className='text-dark fw-bolder mb-3'>Email Verification</h1>
            {minutes > 0 ? (
              <h5 className='text-center'>
                Please wait for {hour} hour and {minutes} minutes before requesting another
                verification link. In the meantime, please check your spam folder for any previous
                email. We appreciate your patience and understanding.
              </h5>
            ) : (
              <h5>
                Please click the button below to generate a new verification link. The waiting
                period has ended, and you can now proceed to request a new link. Thank you for your
                cooperation.
              </h5>
            )}
          </div>

          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              disabled={minutes > 0 ? true : false}
              onClick={handleSubmit}
              className='btn btn-lg btn-primary w-100 mb-5'
            >
              <span className='indicator-label'>Resend link</span>
            </button>
          </div>
        </form>
      )}
    </>
  )
}

export default EmailConfirmation
