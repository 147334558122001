import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {AdminLayout} from '../../_metronic/layout/AdminLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../admin/pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../admin/pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../admin/pages/layout-builder/BuilderPageWrapper'
import TeacherPage from '../admin/modules/teacher/TeacherPage'
import StudentPage from '../admin/modules/student/StudentPage'
import TeacherDetailPage from '../admin/modules/teacher/TeacherDetailPage/TeacherDetailPage'
import StudentDetailPage from '../admin/modules/student/StudentDetailPage/StudentDetailPage'
import ClassesPage from '../admin/modules/classes/ClassesPage'
import {PageTitleUpdater} from '../../_metronic/layout/components/header/PageTitleUpdater'
import {
  ClassesBreadCrumbs,
  DashboardBreadCrumbs,
  StudentsBreadCrumbs,
  TeachersBreadCrumbs,
} from '../breadcrumbs/breadcrums'
import AllClassesOfTeacher from '../admin/modules/teacher/TeacherDetailPage/AllClassesOfTeacher'
import AllClassesOfStudent from '../admin/modules/student/StudentDetailPage/AllClassesOfStudent'
import AllStudentsOfTeacher from '../admin/modules/teacher/TeacherDetailPage/AllStudentsOfTeacher'
import AllTasksOfStudent from '../admin/modules/student/StudentDetailPage/AllTasksOfStudent'

const AdminRoutes = () => {
  const ProfilePage = lazy(() => import('../admin/modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../admin/modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../admin/modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../admin/modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../admin/modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../admin/modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<AdminLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route index element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        <Route
          path='dashboard'
          element={
            <>
              <PageTitleUpdater breadcrumbs={DashboardBreadCrumbs}></PageTitleUpdater>
              <DashboardWrapper />
            </>
          }
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route
          path='teacher'
          element={
            <>
              {/* <PageTitle breadcrumbs={chatBreadCrumbss}>Teachers</PageTitle> */}
              <PageTitleUpdater breadcrumbs={TeachersBreadCrumbs}>Teachers</PageTitleUpdater>
              <TeacherPage />
            </>
          }
        />
        <Route
          path='teacher/:id'
          element={
            <>
              <TeacherDetailPage />
            </>
          }
        />
        <Route path='teacher/:id/all-classes' element={<AllClassesOfTeacher />} />
        <Route path='teacher/:id/all-students' element={<AllStudentsOfTeacher />} />

        <Route
          path='student'
          element={
            <>
              <PageTitleUpdater breadcrumbs={StudentsBreadCrumbs}>Students</PageTitleUpdater>
              <StudentPage />
            </>
          }
        />
        <Route
          path='student/:id'
          element={
            <>
              <StudentDetailPage />
            </>
          }
        />
        <Route path='student/:id/all-classes' element={<AllClassesOfStudent />} />
        <Route path='student/:id/all-tasks' element={<AllTasksOfStudent />} />

        <Route
          path='classes'
          element={
            <>
              <PageTitleUpdater breadcrumbs={ClassesBreadCrumbs}>Classes</PageTitleUpdater>
              <ClassesPage />
            </>
          }
        />

        {/* <Route path='classes/:id' element={<ClassDetailPage />} /> */}
        {/* <Route path='tasks' element={<TaskPage />} /> */}

        <Route path='menu-test' element={<MenuTestPage />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {AdminRoutes}
