export const screenSizeConfig = {
  xl: {
    screenSize: 'xl',
    topValue: {left: 304.71, right: 304.31},
    calculateTop: {
      orientationLeft: {
        conditionGreaterThan4: 305.41,
        conditionLessThan4: {
          conditionEqual1: 314.01,
          conditionNotEqual1: 306.67,
        },
      },
      orientationRight: {
        conditionGreaterThan4: 303.01,
        conditionLessThan4: 303.01,
      },
    },
    calculateTopLeftMid: {
      conditionEqualTo1: 307.01,
      decrement: 0.3,
    },
    calculateTopRightMid: {
      conditionEqualTo2: 305.51,
      decrement: 0.2,
    },
  },
  lg: {
    screenSize: 'lg',
    topValue: {left: 243.41, right: 242.86},
    calculateTop: {
      orientationLeft: {
        conditionGreaterThan4: 243.6,
        conditionLessThan4: {
          conditionEqual1: 251.01,
          conditionNotEqual1: 245.17, //241.51,
        },
      },
      orientationRight: {
        conditionGreaterThan4: 241.84,
        conditionLessThan4: 241.67, //issue here
      },
    },
    calculateTopLeftMid: {
      conditionEqualTo1: 246.01,
      decrement: 0.3,
    },
    calculateTopRightMid: {
      conditionEqualTo2: 244.01,
      decrement: 0.2,
    },
  },
  sm: {
    screenSize: 'sm',
    topValue: {left: 88.07, right: 87.61},
    calculateTop: {
      orientationLeft: {
        conditionGreaterThan4: 87.81,
        conditionLessThan4: {
          conditionEqual1: 91.01,
          conditionNotEqual1: 88.67,
        },
      },
      orientationRight: {
        conditionGreaterThan4: 87.17,
        conditionLessThan4: 87.01,
      },
    },
    calculateTopLeftMid: {
      conditionEqualTo1: 89.01,
      decrement: 0.3,
    },
    calculateTopRightMid: {
      conditionEqualTo2: 88.51,
      decrement: 0.2,
    },
  },
  md: {
    screenSize: 'md',
    topValue: {left: 180.436, right: 180.16},
    calculateTop: {
      orientationLeft: {
        conditionGreaterThan4: 180.81,
        conditionLessThan4: {
          conditionEqual1: 186.01,
          conditionNotEqual1: 181.67,
        },
      },
      orientationRight: {
        conditionGreaterThan4: 178.92,
        conditionLessThan4: 179.01,
      },
    },
    calculateTopLeftMid: {
      conditionEqualTo1: 182.01,
      decrement: 0.3,
    },
    calculateTopRightMid: {
      conditionEqualTo2: 181.01,
      decrement: 0.2,
    },
  },
}
