import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {UserLayout} from '../../_metronic/layout/UserLayout'
import {HomePage} from '../user/pages/home/HomePage'
import ClassDetailPage from '../admin/modules/classes/ClassDetailPage'
import {useAuth} from '../modules/auth'
import {UserRoles} from '../config/UserRoles'
import StudentPage from '../admin/modules/student/StudentPage'
import StudentDetailPage from '../admin/modules/student/StudentDetailPage/StudentDetailPage'
import TeacherClassesPage from '../admin/modules/teacher/TeacherClassesPage'
import StudentClassesPage from '../admin/modules/student/StudentClassesPage'
import Home from '../user/pages/home/Home'
import {PageTitleUpdater} from '../../_metronic/layout/components/header/PageTitleUpdater'
import {
  DashboardBreadCrumbs,
  StudentClassTasksBreadCrumbs,
  StudentClassesBreadCrumbs,
  TeacherClassesBreadCrumbs,
  TeachersBreadCrumbs,
} from '../breadcrumbs/breadcrums'
import {DashboardWrapper} from '../admin/pages/dashboard/DashboardWrapper'
import BuilderPageWrapper from '../admin/pages/layout-builder/BuilderPageWrapper'
import DashboardTeacher from '../admin/modules/teacher/DashboardTeacher'
import DashboardStudent from '../admin/modules/student/DashboardStudent'

const UserRoutes = () => {
  const AboutUs = lazy(() => import('../user/modules/about/AboutPage'))
  const ContactUs = lazy(() => import('../user/modules/contact/ContactPage'))
  const ProfilePage = lazy(() => import('../user/modules/profile/ProfilePage'))
  const TOCPage = lazy(() => import('../user/modules/TOC/TOCPage'))

  //For checking what is the userRole
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<UserLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        {currentUser !== undefined && currentUser.user_role === UserRoles.teacher ? (
          <>
            {/* if loggedIn user is user then navigate to Teacher routes */}

            <Route
              path='dashboard'
              element={
                <>
                  <PageTitleUpdater breadcrumbs={DashboardBreadCrumbs}></PageTitleUpdater>
                  <DashboardTeacher />
                </>
              }
            />

            <Route
              path='classes'
              element={
                <>
                  <PageTitleUpdater breadcrumbs={TeacherClassesBreadCrumbs}>
                    Classes
                  </PageTitleUpdater>
                  <TeacherClassesPage />
                </>
              }
            />
            <Route
              path='classes/:id'
              element={
                <>
                  <ClassDetailPage />
                </>
              }
            />
          </>
        ) : (
          <>
            {/* if loggedIn user is user then navigate to Student routes */}
            {/* <Route path='home' element={<Home />} /> */}

            <Route
              path='dashboard'
              element={
                <>
                  <PageTitleUpdater breadcrumbs={DashboardBreadCrumbs}></PageTitleUpdater>
                  <DashboardStudent />
                </>
              }
            />

            <Route path='classes' element={<StudentClassesPage />} />
            <Route
              path='classes/:id'
              element={
                <>
                  <HomePage />
                </>
              }
            />
          </>
        )}

        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='about'
          element={
            <SuspensedView>
              <AboutUs />
            </SuspensedView>
          }
        />

        <Route
          path='contact'
          element={
            <SuspensedView>
              <ContactUs />
            </SuspensedView>
          }
        />

        <Route
          path='terms'
          element={
            <SuspensedView>
              <TOCPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {UserRoutes}
