export const formatNumber = (count: number) => {
  if (count >= 1000000000) {
    return (count / 1000000000).toFixed(1) + 'B'
  } else if (count >= 1000000) {
    return (count / 1000000).toFixed(1) + 'M'
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1) + 'K'
  } else {
    return count.toString()
  }
}

export function truncateText(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }
  return text
}
