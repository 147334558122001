//This file of API Client is specifically used for hooks only
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

interface FetchResponse<T> {
  count: number
  data: T[]
}

class APIClientHooks<T> {
  endpoint: string
  constructor(ep: string) {
    this.endpoint = ep
  }

  // Post Data
  postData = (data: T) => {
    return axios
      .post(this.endpoint, data)
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error posting Data:', error)
        throw error
      })
  }
  //Get All Data
  getAllData = () => {
    return axios
      .get<FetchResponse<T>>(this.endpoint)
      .then((res) => {
        return res.data.data
      })
      .catch((error) => {
        console.error('Error fetching Data:', error)
        throw error
      })
  }
  //Get Data By ID
  getData = (id: number) => {
    return axios
      .get<T>(this.endpoint + '/' + id)
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error fetching Data:', error)
        throw error
      })
  }

  //Get a single data without ID
  //TODO There should be function overloading here
  getSingleData = () => {
    return axios
      .get<T>(this.endpoint)
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error fetching Data:', error)
        throw error
      })
  }

  //Update Data

  updateData = (id: number, data: T) => {
    return axios
      .put(this.endpoint + '/' + id, data) // Use PUT or PATCH request as appropriate
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error updating Data:', error)
        throw error
      })
  }
}

export default APIClientHooks
