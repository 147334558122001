import axios from 'axios'
import {AuthModel, UserModel, UserModelTest} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const FORGET_PASSWORD = `${API_URL}/auth/forget-password`
export const RESET_PASSWORD = `${API_URL}/auth/reset-password`
export const VERIFY_EMAIL_URL = `${API_URL}/auth/verify-email`
export const RESEND_EMAIL_URL = `${API_URL}/auth/resend-link`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(name: string, email: string, password: string) {
  return axios.post(REGISTER_URL, {
    name,
    email,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(FORGET_PASSWORD, {
    email,
  })
}

// reset password
export function resetPassword(password: string, token: string | undefined) {
  return axios.post<{message: string}>(RESET_PASSWORD, {
    newPassword: password,
    forgetPasswordToken: token,
  })
}

// verify token
export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    auth_token: token,
  })
}

// email verification
export function verifyEmail(token: string) {
  return axios.post(VERIFY_EMAIL_URL, {
    auth_token: token,
  })
}

// resend email verification link
export function resendLink(email: string) {
  return axios.post(RESEND_EMAIL_URL, {
    email: email,
  })
}

//!Test
// verify token
export function getUserByTokenTest(token: string) {
  return axios.post<UserModelTest>(GET_USER_BY_ACCESSTOKEN_URL, {
    auth_token: token,
  })
}
